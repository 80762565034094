.header {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.bread__crumbs {
    display: flex;
    align-items: center;
    gap: 10px;
    .crumb {
        color: var(--breadcrumb-item-color, #4B5563);
        font-family: Inter;
        font-size: 12px;
        font-style: normal;
        line-height: 100%;
        font-weight: 400;
        cursor: pointer;
    }
    .crumb:hover {
        font-weight: 600;
    }
}

.header__text {
    color: var(--Black-60, #45464E);
    font-family: Inter;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}