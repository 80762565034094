.dropdown__title { 
    color: #212121;
    font-family: "Nunito Sans";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    width: 100%;
    text-align: start;
}

.input__label {
    border-radius: 12px;
    padding: 8px;
    border: 1px solid;
}

.input__label:focus {
    outline: none;
}