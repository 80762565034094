
.timer__body {
    display: flex;
    flex : 1 1 0%;
    flex-direction: column;
    align-items: center;
    gap: 15%;
    height: 100%;
    justify-content: center;

    div {
        color: #567F84;
        text-align: center;
        font-family: Inter;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        width: 80%;
    }

    img {
        height: 40%;
    }
}