.HighlightedWord {
    /* background-color: yellow; */
    background-color: rgb(210 245 210);
  }
  
  .transcriptContainer {
    /* margin-top: 20px; */
    width: 100%;
    border: none;
    outline: 0px;
  }
  
  .transcriptText {
    font-size: 16px;
    line-height: 1.5;
    overflow: auto;
    overflow-wrap: anywhere;
    padding: 4px;
  }

.audioTranscriptionEditModeTextArea {
  width: 100%;
  border: none;
  outline: 0px;
}
  