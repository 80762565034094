.modelGroupReactFlowContainer {
    width: 100%;
    height: 580px;
}
@media (max-height: 604px) {
    .modelGroupReactFlowContainer {
        height: 234px;
    }
}
@media (max-height: 971px) {
    .modelGroupReactFlowContainer {
        height: 470px;
    }
}

.modelGroupAccordian:last-child {
    margin-bottom: 10px !important;
}

.accordianSummaryRoot {
    /* margin-top: 15px;
    margin-bottom: 15px; */
    cursor: default;
}

.reactFlowViewMode {
    overflow: auto !important;
}
/* .reactFlowViewMode,
.reactFlowViewMode .react-flow__renderer .react-flow__pane {
    cursor: default !important;
} */

.modelGroupLayoutEditButtonsContainer {
    display: flex;
    column-gap: 20px;
    justify-content: end;
}
.modelGroupLayoutEditButtonsContainer .cancelButton,
.modelGroupLayoutEditButtonsContainer .saveChangesButton {
    font-size: 11px;
}

.noModelGroupsFoundText {
    width: 100%;
    height: 70vh;
    display: flex;
    justify-content: center;
    align-items: center;
}
.modelGroupName {
    max-width: 70vw;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}

@media screen and (max-width: 1010px){
    .modelGroupName {
        max-width: 60vw;
    }
}
@media screen and (max-width: 830px){
    .modelGroupName {
        max-width: 50vw;
    }
}
@media screen and (max-width: 630px){
    .modelGroupName {
        max-width: 30vw;
    }
}
@media screen and (max-width: 430px){
    .modelGroupName {
        max-width: 20vw;
    }
}

