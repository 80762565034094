body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #f0f5fc !important;
}

code {
  font-family: "Nunito Sans", sans-serif;
}

@font-face {
  font-family: 'Roboto-Medium';
  src: local('Poppins'), url(./assets/fonts/roboto/Roboto-Medium.ttf) format('truetype');
}
@font-face {
  font-family: 'NunitoSans-Regular';
  src: local('Nunito_Sans'), url(./assets/fonts/Nunito_Sans/NunitoSans-Regular.ttf) format('truetype');
}
