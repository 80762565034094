.rounded__button {
    padding: 5px;
    cursor: pointer;
    width: 100%;
    min-height: 2rem;
    background-color: white;
    color: #1E293B;
    border-radius: 9999px;
    text-align: center;
    word-wrap: break-word;
    overflow-wrap: break-word;
    white-space: normal; 
    border: 1px solid #1E293B;
}

.rounded__button:hover {
    background-color: #1E293B;
    color: white;
}