/* .modelsList {
    gap: 30px 20px;
    width: 100%;
    height: 100%;
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    height: 100%;
}
.modelsListContainer {
    margin-left: 11px;
    height: 100%;
} */

.noModelsFoundText {
    width: 100%;
    height: 87vh;
    display: flex;
    justify-content: center;
    align-items: center;
}