@import url('https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&display=swap');
/* Main container styling */
.container {
  width: 100%;
  /* height: 100vh; */
  background-color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  font-family: 'Inter', sans-serif;
  font-style:'normal'
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 95%;
  /* position: absolute; */
  height: 10vh;
  top: 0;
  padding: 20px 0;
  box-sizing: border-box;
}

.logo {
  width: 150px;
}

.nav {
  display: flex;
  gap: 20px;
}

.nav a {
  text-decoration: none;
  color: #333;
  font-weight: 500;
}
.rlefLogoWrapper{
  display: flex;
  gap: 0.7rem;
  z-index: 500;
  align-items: center;
}

/* Responsive Navigation */
@media (max-width: 768px) {
  .nav {
    flex-direction: column;
    align-items: center;
    width: 100%;
  }

  .nav a {
    margin: 10px 0;
    /* Add spacing between links */
  }

  .content h1 {
    font-size: 20px;
    /* Reduce font size for smaller screens */
  }

  .content p {
    font-size: 14px;
    /* Reduce font size for smaller screens */
    padding: 0 10px;
    /* Add some padding */
  }

  .searchContainer {
    /* width: 90%; */
    /* Increase width on smaller screens */
  }
}

.hamburgerProfileContainer{
  display: flex;
  align-items: center;
  gap:2rem;
  /* background-color: yellow; */
}
.hamburgerIconWrapper{
  width:1.5rem;
  height:1.5rem;
  display:grid;
  place-content: center;
  position: relative;
  z-index:2;
}


.navLinks{
  position: absolute;
  display: flex;
  width:180px;
  flex-direction: column;
  gap:1rem;
  top:100px;
  right:0;
  font-size: 1rem;
  background-color: transparent;
  text-align: right;
  /* opacity: 0; */
  transform: translateY(-20px);
  transition: opacity 0.5s ease, transform 0.5s ease;
}

.navLinks a{
  text-decoration: none !important;
  color:#5A636C;
  font-weight: 500;
  font-family: 'Inter';
  opacity: 0;
  transform: translateX(-20px);
  animation: slideIn 0.5s ease forwards;
}

.navLinks.open {
  opacity: 1;
  transform: translateY(0);
}

.navLinks a {
  text-decoration: none;
  color: #5A636C;
  /* font-weight: bold; */
  /* opacity: 0; */
  transform: translateX(-20px);
  animation: slideIn 0.5s ease forwards;
}

.navLinks a:nth-child(1) {
  animation-delay: 0.1s;
}

.navLinks a:nth-child(2) {
  animation-delay: 0.2s;
}

.navLinks a:nth-child(3) {
  animation-delay: 0.3s;
}

.navLinks a:nth-child(4) {
  animation-delay: 0.4s;
}

.navLinks a:nth-child(5) {
  animation-delay: 0.5s;
} 

 @keyframes slideIn {
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

.profileIconWrapper{
  width:32px;
  height:32px;
  position:relative;
  cursor: pointer;
  z-index:2;
}

.profileIconDrawer{
  position: absolute;
  top:45px;
  left:-50%;
  font-size: 1rem;
  border:1px solid grey;
  border-radius: 8px;
  width:75px;
}

.logoutWrapper{
  width:100%;
  padding:.5rem;
  
}
.content {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: left;
  justify-content: flex-start;
  /* transition: transform 0.5s ease-out; 
  transform: translateX(0);  */
}

.contentIntoSplitColumns {
  display: flex;
  flex-direction: column;
  align-items: center;
  /* text-align: center; */
  text-align: left;
  justify-content: end;
  /* height: 52vh; */
  /* width: 90%; */
  flex-grow: 1;
  position: relative;
  height:100vh;
  margin-top:-10vh;
}

.backgroundEllipseWrapper{
  width:100%;
  overflow:hidden;
  position: absolute;
  top:56%
}

.ellipse{
  width:100%;
  object-fit: contain;
}

.content h1 {
  font-size: 2.3rem;
  font-weight: 700;
}

.content p {
  font-size: 14px;
  color: #666;
  /* margin-bottom: 30px; */
}

/* Search container */
.searchContainer {
  display: flex;
  align-items: center;
  position: relative;
  width: 70%;
  /* max-width: 600px; */
  z-index: 20;
  background: white;
  /* border-radius: 50px; */
  padding: 16px;
  /* border: 1px solid transparent; */
  margin: 0 auto;
  /* margin-top:20vh; */
  border: 1px solid #A6B2B8;
  /* transition: transform 0.3s ease, box-shadow 0.3s ease; */
}

/* Responsive Search Bar */

.profileIcon img {
  width: 35px;
  height: 35px;
  border-radius: 50%;
}



/* .content h1 {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 10px;
} */

.blur-background {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  /* height: 100%; */
  backdrop-filter: blur(5px);
  /* Apply blur effect */
  transition: backdrop-filter 1s ease;
  /* Transition for blur */
  z-index: 1;
}

/* Search bar container with colorful glow */
.searchContainer {
  display: flex;
  align-items: center;
  position: relative;
  /* width: 784px; */
  height: 64px;
  background: white;
  /* border-radius: 50px; */
  border-radius:24px;
  padding: 15px;
  margin-top:30vh;
  /* border: 2px solid transparent; */
  /* box-shadow: 0 0 5px rgba(255, 97, 43, 0.7),
    0 0 5px rgba(2, 255, 180, 0.7),
    0 0 5px rgba(253, 7, 199, 0.7),
    0 0 5px rgba(54, 102, 255, 0.7); */

  /* animation: rotateColors 5s linear infinite;
  transition: transform 1s ease, box-shadow 0.3s ease; */
  z-index: 2;
}

/* Search input */
.searchBar {
  width: 100%;
  /* padding: 15px 20px; */
  min-width: 20vw;
  border: none;
  outline: none;
  font-size: 16px;
  border-radius: 50px;
  color:#838C95;
  font-family:'Inter';
}


.searchIcon {
  position: absolute;
  left: 20px;
  font-size: 20px;
  color: #666;
}

.searchContainer.active {
  /* position: absolute; */
  /* top: 10%;  */
  /* transform: translateY(-50%); */
  /* transition: border-radius .3s ease; */
  z-index: 30;
  border-radius:0 0 24px 24px;
  
  /* animation: rotateColors 5s linear infinite, moveDown 1.4s cubic-bezier(0.25, 1.0, 0.5, 1.0) forwards; */
  /* Use keyframes for upward movement */

  /* transition: transform 1.2s cubic-bezier(0.25, 1.0, 0.5, 1.0); */
  /* box-shadow: 0 0 30px rgba(255, 97, 43, 0.8),
              0 0 30px rgba(2, 255, 180, 0.8),
              0 0 30px rgba(253, 7, 199, 0.8),
              0 0 30px rgba(54, 102, 255, 0.8);  */
}

/* Non-active state for the search container */
.searchContainer:not(.active) {
  /* animation: rotateColors 5s linear infinite, moveUp 1.2s cubic-bezier(0.25, 1.0, 0.5, 1.0) forwards; */
}

/* Hover effect on search container */
.searchContainer:hover {
  /* transform: scale(1.05); */
  /* Increased scale for a more noticeable effect */
  /* box-shadow: 0 0 10px rgba(255, 97, 43, 0.8),
    0 0 10px rgba(2, 255, 180, 0.8),
    0 0 10px rgba(253, 7, 199, 0.8),
    0 0 10px rgba(54, 102, 255, 0.8); */
  /* Slightly increased shadow size */
}

.styleOfSearchContainerInSplitIntoTwoColumns{
  border-radius: 24px !important;
}

@keyframes moveDown {
  0% {
    transform: translateY(0%);
    /* Start from the final upward position */
  }

  100% {
    transform: translateY(450%);
    /* End at the original position */
  }
}

@keyframes moveUp {
  0% {
    transform: translateY(450%);
    /* Start at the original position */
  }

  100% {
    transform: translateY(0);
    /* Move upwards completely */
  }
}

/* Keyframes for rotating the gradient */
@keyframes rotateColors {
  0% {
    border-color: #FF612B;
    box-shadow: 0 0 5px rgba(255, 97, 43, 0.8),
      0 0 5px rgba(2, 255, 180, 0.8),
      0 0 5px rgba(253, 7, 199, 0.8),
      0 0 5px rgba(54, 102, 255, 0.8);
  }

  25% {
    border-color: #02FFB4;
    box-shadow: 0 0 5px rgba(2, 255, 180, 0.8),
      0 0 5px rgba(253, 7, 199, 0.8),
      0 0 5px rgba(54, 102, 255, 0.8),
      0 0 5px rgba(255, 97, 43, 0.8);
  }

  50% {
    border-color: #FD07C7;
    box-shadow: 0 0 5px rgba(253, 7, 199, 0.8),
      0 0 5px rgba(54, 102, 255, 0.8),
      0 0 5px rgba(255, 97, 43, 0.8),
      0 0 5px rgba(2, 255, 180, 0.8);
  }

  75% {
    border-color: #3666FF;
    box-shadow: 0 0 5px rgba(54, 102, 255, 0.8),
      0 0 5px rgba(255, 97, 43, 0.8),
      0 0 5px rgba(2, 255, 180, 0.8),
      0 0 5px rgba(253, 7, 199, 0.8);
  }

  100% {
    border-color: #FF612B;
    box-shadow: 0 0 5px rgba(255, 97, 43, 0.8),
      0 0 5px rgba(2, 255, 180, 0.8),
      0 0 5px rgba(253, 7, 199, 0.8),
      0 0 5px rgba(54, 102, 255, 0.8);
  }
}

.searchBarchatContainer{
  position: absolute;
  display: flex;
  flex-direction: column;
  margin-bottom: 12px;
  padding: 12px;
  padding-bottom: 0px;
  bottom: 100%; 
  /* transform: translate(0,-100%); */
  left: 0; 
  width: 100%;
  /* height: 70dvh; */
  height: calc(32vh + 0.5px);
  justify-content: center;
  border:1px solid black;
  background-color: white;
  /* background: white; */
  /* background-color:transparent; */
  /* border: 1px solid #ccc; */
  border-radius: 16px;
  /* Optional rounded corners */
  /* max-height: 200px; */
  /* Max height */
  overflow-x: hidden;
  /* Scroll if too many items */
  z-index: 1000;
  /* box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.24); */
}

/* .searchBarchatContainer::before {
  content: "";
  position: absolute;
  top: 0;
  left: 20%; 
  width: 60%; 
  border-top: 2px solid black; 
} */


.searchBarchatContainerTesting {
  position: absolute;
  top: 0;
  left: 0;
  transform: translateY(-100%);
  opacity: 0;
  visibility: hidden;
  z-index: -1;
  height:0;
  /* transition: 'all 0.5s ease-in-out'  */
  /* transition: transform 0.3s ease-in-out, opacity 0.3s ease-in-out !important; */
  
}

.searchBarchatContainerShowTesting{
  position:absolute;
  top:0;
  left:-1px;
  background-color:#FFFFFF;
  width:calc(100% + 2px);
  height:calc(37vh + 0.5px);
  transition: all 0.5s ease-in-out;
  opacity: 1;
  visibility: visible;
  transform: translateY(-100%);
  z-index: 1;
  overflow:auto;
  border:1px solid #A6B2B8;
  border-bottom: none;
  border-radius: 24px 24px 0 0;
  
}

.searchBarchatContainerShowTesting::-webkit-scrollbar-track {
  background: transparent !important;
  margin-top: 20px;
}

.styleOfChatContainerWhenInSplitIntoTwoColumns{
  height:81vh;
  border:none;
  transform: translateY(-101%);
  background-color: #FFFFFF;
}
.markdownContent * {
  text-align: left;
  margin: 0;
}

.dropdown {
  position: absolute;
  margin-top: 1rem;
  padding: 12px;
  top: 100%;
  left: 0;
  width: 100%;
  height: 70dvh;
  background: white;
  /* background-color:transparent; */
  border: 1px solid #ccc;
  border-radius: 16px;
  /* Optional rounded corners */
  /* max-height: 200px; */
  /* Max height */
  overflow-y: auto;
  /* Scroll if too many items */
  z-index: 1000;
  box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.24);
}
.customScroll {
  overflow-y: auto; /* Enable vertical scroll */
  /* max-height: 400px;  */
}

.customScroll::-webkit-scrollbar {
  width: 8px;
}

.customScroll::-webkit-scrollbar-track {
  background: #F0F2F4; /* Background of the scrollbar track */
  border-radius: 4px;
}

.customScroll::-webkit-scrollbar-thumb {
  background-color: #c3c4c6; /* Color of the scrollbar thumb */
  border-radius: 4px;
  border: 2px solid #F0F2F4; /* Padding around thumb for better aesthetics */
  height: 30px;
}

.customScroll::-webkit-scrollbar-thumb:hover {
  background-color: #b8b9bb; /* Darker color on hover */
}

.dropdown::-webkit-scrollbar,
.filterContainer::-webkit-scrollbar {
  width: 8px;
}

.dropdown::-webkit-scrollbar-track,
.filterContainer::-webkit-scrollbar-track {
  background: #F0F2F4; /* Background of the scrollbar track */
  border-radius: 4px;
}

.dropdown::-webkit-scrollbar-thumb,
.filterContainer::-webkit-scrollbar-thumb {
  background-color: #5C6A7F; /* Color of the scrollbar thumb */
  border-radius: 4px;
  border: 2px solid #F0F2F4; /* Padding around thumb for better aesthetics */
}

.dropdown::-webkit-scrollbar-thumb:hover,
.filterContainer::-webkit-scrollbar-thumb:hover {
  background-color: #4B5563; /* Darker color on hover */
}

.dropdownItem {
  padding: 10px;
  /* Padding for each item */
  cursor: pointer;
  /* Cursor change on hover */
}

.dropdownItem:hover {
  background: #f0f0f0;
  /* Highlight effect on hover */
}

/* ai news, popular model,latest models section */

.card {
  border-radius: 15px;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
}

.news-image {
  width: 80px;
  border-radius: 10px;
}

.sample-image {
  width: 100%;
  height: auto;
  border-radius: 10px;
}

/* Buttons */
.action-button {
  margin-top: 20px;
  width: 100%;
  border-radius: 20px;
}

.section-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

/* Media Queries for responsiveness */
@media (max-width: 768px) {
  .news-image {
    width: 60px;
  }

  .action-button {
    margin-top: 15px;
  }
}

@media (max-width: 480px) {
  .news-image {
    width: 50px;
  }

  .sample-image {
    width: 100%;
    height: auto;
  }
}

.columnSectionText {
  background: linear-gradient(272deg, rgba(118, 107, 250, 0.70) 46.23%, rgba(35, 186, 202, 0.80) 109.2%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent; 
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: -0.72px;
  font-family:'Inter';
}

.aiNewsContainer {
  border-radius: 16px;
  /* background: rgba(63, 133, 247, 0.10); */
  background: #FAFAFA;
  border: 1.5px solid rgba(240, 240, 240, 0.60);
  padding: 16px;
}

.popularModelsContainer {
  border-radius: 16px;
  /* background: rgba(124, 213, 167, 0.12); */
  background: #FAFAFA;
  border: 1.5px solid rgba(240, 240, 240, 0.60);
  padding: 16px;
  font-family: 'Inter';
  /* min-width: 423px; */
}

.latestModelsContainer {
  border-radius: 16px;
  /* background: rgba(242, 157, 65, 0.10); */
  background: #FAFAFA;
  border: 1.5px solid rgba(240, 240, 240, 0.60);
  padding: 16px;
}

.filterContainer {
  border: 0.7px solid rgba(100, 116, 139, 0.30);
  background: #FFF;
  border-radius: 16px;
  display: flex;
  align-items: center;
  padding: 0;
}

.filterText {
  color: #64748B;
  text-align: center;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  padding-left: 5px;
  font-family: 'Inter';
}

.card-item {
  width: 413px;
  height: 129px;
  padding: 8px;
  border-radius: 12px;
  border: 1px solid #E0E0E0;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}

.card-image {
  width: 104px;
  height: 113px;
  border-radius: 6px;
}

.trending-chip {
  padding: 2px 8px;
  background: #EFF9F4;
  border-radius: 4px;
  border: 0.8px solid #0AB257;
  display: flex;
  align-items: center;
}

.trending-chip .icon {
  width: 16px;
  height: 16px;
  background-color: #02FFB4;
  border-radius: 4px;
  margin-right: 4px;
}

.trending-chip-text {
  color: #0AB257;
  font-size: 10px;
  /* font-family: 'Inter', sans-serif; */
  font-weight: 500;
}

.author-date {
  color: #008D9C;
  font-size: 12px;
  /* font-family: 'Inter', sans-serif; */
  font-weight: 500;
}

.news-title {
  color: #101828;
  font-size: 14px;
  /* font-family: 'Inter', sans-serif; */
  font-weight: 600;
}

.news-description {
  color: #667085;
  font-size: 11px;
  /* font-family: 'Inter', sans-serif; */
  font-weight: 400;
}

.design-chip {
  background: #F0F9FF;
  color: #026AA2;
}

.research-chip {
  background: #FDF2FA;
  color: #C11574;
}

/* search dropdown stylings */
.chatInterfaceMainHeading{
  font-size: 40px;
  font-weight: 700;
  line-height: 'normal';
  background: linear-gradient(93deg, #8CD3D6 0%, #DB94D4 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  /* These are necessary for the gradient to appear on text */
  background-clip: text;
  color: transparent;
  align-self: center;
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
}
.SearchModelsContainer {
  border-radius: 8px;
  background: rgba(92, 106, 127, 0.08);
  padding: 8px;
  padding-left: 6px;
}

.roundedDiv {
  padding: 16px;
  border-radius: 8px;
  background: #FFF;
}

.ellipsisText{
  display: -webkit-box; 
  -webkit-box-orient: vertical; 
  overflow: hidden; 
  text-overflow: ellipsis;
  -webkit-line-clamp: 2; /* Limit to 2 lines */
  line-clamp: 2; /* Fallback for non-webkit browsers */
  white-space: normal; 
}

.blurOverlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  backdrop-filter: blur(8px); /* Apply blur */
  -webkit-backdrop-filter: blur(8px); /* Safari support */
  z-index: 5;
  pointer-events: none; /* Prevent interactions */
  transition: opacity 0.3s ease;
  opacity: 1;
}
.popularModelCard{}
.latestModelCard{}
.popularModelCard:hover{
  outline:1.5px solid #A0D7B9;
}
.latestModelCard:hover{
  outline:1.5px solid #FCBE78;
}


.backgroundAnimationAbsoluteWrapper {
  position: fixed;
  top: 0;
  left: 0;
  /* width: 100%; */
  /* height: 78vh; */
  width: 100vw;
  height: 100vh;
  z-index: 0;
  /* background-size: cover;
  background-position: center; */
  /* background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 100%); */
  overflow:hidden;
}


.blobGreen{
  width:20%;
  height: 20%;
  background-color: #009C9C;
  border-radius: 50%;
  filter: blur(clamp(100px,12vw,400px));
  position:absolute;
  animation: moveInRectangleClockwise 12s infinite;
}

.blobViolet{
  width:20%;
  height:20%;
  background-color: #0C009C;
  border-radius: 50%;
  filter: blur(clamp(100px,12vw,400px));
  position:absolute;
  animation: moveInRectangleCounterClockwise 12s infinite;
}

@keyframes moveInRectangleClockwise {
  0%   { top: 0; left: 0; }
  25%  { top: 80%; left: 0; }  
  50%  { top: 80%; left: 80%; } 
  75%  { top: 0; left: 80%; }  
  100% { top: 0; left: 0; }    
}

@keyframes moveInRectangleCounterClockwise {
  0%   { top: 80%; left: 80%; }
  25%  { top: 0; left: 80%; } 
  50%  { top: 0; left: 0; }  
  75%  { top: 80%; left: 0; } 
  100% { top: 80%; left: 80%; }   
}

.showMoreAIDetailsWindowCss{
  border-top: 1px solid #D7D6D6;
  border-bottom: 1px solid #D7D6D6;
  border-right: 1px solid #D7D6D6;
  /* border-radius: 10px; */
  display: flex;
  flex-direction: column;
  /* box-shadow: 0px 0px 32px 0px rgba(0, 0, 0, 0.08); */
  height: 100vh;
  width: 100%;
  z-index: 100;
  margin-top: -10vh;
  /* Starting position and animation */
  transform: translateY(100%);
  opacity: 0;
  animation: slideIn 1s ease-out forwards;
  background: #FFF
}
  
.showMoreAIDetailsWindowHeader {
  border-bottom: 1px solid #D7D6D6;
  background: #ECEEEE;
  display: flex;
  padding: 4px 24px 4px 16px;
  justify-content: space-between;
  align-items: center;
}
.chatWindow{
  /* transition: transform 0.5s ease-out;
  transform: translateX(0); */
  border:2px solid red;
  animation: slideLeftAnimation 1s ease-out forwards;
}
/* .slideLeft {
  transform: translateX(-10%);
} */

@keyframes slideIn {
 from {
   transform: translateY(100%);
   opacity: 0;
 }
 to {
   transform: translateY(0);
   opacity: 1;
 }
}
@keyframes slideOut {
  from {
    transform: translateY(0);
    opacity: 0;
  }
  to {
    transform: translateY(100%);
    opacity: 1;
  }
 }
/* Keyframe for sliding left */
@keyframes slideLeftAnimation {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(-1%);
  }
}
/* Keyframe for sliding right */
@keyframes slideRightAnimation {
  from {
    transform: translateX(-1%);
  }
  to {
    transform: translateX(0);
  }
}

/* Apply slide left animation */
.slideLeft {
  z-index: 10;
  margin-left: 2rem;
  animation: slideLeftAnimation 0.5s ease-out forwards;
}

/* Apply slide right animation */
.slideRight {
  animation: slideRightAnimation 0.5s ease-out forwards;
}

.chatResultCardOnHover{
  transition: outline 0.3s ease, box-shadow 0.3s ease;
}
.chatResultCardOnHover:hover{
  /* outline:1px solid #008D9C; */
  outline: 2px solid #00a5bf;
  box-shadow: 0px 0px 0px 6px #00a5bf45;
}
.gifLoader{
  width:3rem;
  height:3rem;
  border-radius: 50%;
  background-color: white;
  margin:1rem auto;
  border:1px solid lightgrey;
}


@media screen and (max-width: 1366px) {
  /* .content {
    min-height: 52vh;
  } */
  /* .contenHeight {
    height: 97vh;
  } */
}

@media screen and (min-width: 1367px) and (max-width: 1920px) {
  .contenHeight {
    height: 60vh;
  }
}

@media screen and (min-width: 1366px) and (max-width: 1920px) {
  .contenHeight {
    height: 97vh;
  }
}

@media screen and (min-width: 1920px) and (max-width: 2560px) {
  .contenHeight {
    height: 97vh;
  }
}

@media screen and (min-width: 2560px) and (max-width: 3840px) {
  .contenHeight {
    height: 97vh;
  }
}

.stickySearchContainer{
  display: flex;
  align-items: center;
  position: relative;
  width: 71%;
  /* max-width: 600px; */
  z-index: 20;
  background: white;
  /* border-radius: 50px; */
  padding: 16px;
  /* border: 1px solid transparent; */
  margin: 0 auto;
  /* margin-top:20vh; */
  border: 1px solid #A6B2B8;
  /* transition: transform 0.3s ease, box-shadow 0.3s ease; */

  /* --- */
  display: flex;
  align-items: center;
  position: relative;
  /* width: 784px; */
  height: 50px;
  background: white;
  /* border-radius: 50px; */
  border-radius:24px;
  padding: 15px;
  /* margin-top:20vh; */
  /* border: 2px solid transparent; */
  /* box-shadow: 0 0 5px rgba(255, 97, 43, 0.7),
    0 0 5px rgba(2, 255, 180, 0.7),
    0 0 5px rgba(253, 7, 199, 0.7),
    0 0 5px rgba(54, 102, 255, 0.7); */

  /* animation: rotateColors 5s linear infinite;
  transition: transform 1s ease, box-shadow 0.3s ease; */
  z-index: 100000;
}

/* split-screen-drag-bar-css */
.fullWidth{
  width:100vw
}
.grid {
  display: grid;
  z-index: 400;
  /* grid-template-columns: 1fr 10px 1fr; */
  grid-template-columns: 3fr 16px 7fr;
  /* grid-template-columns: 3fr 10px 7fr; */
  width: 100vw;
}
/* .grid > div:first-child {
  min-width: 10vw;
}

.grid > div:last-child {
  min-width: 20vw;
} */
.gutter-col {
  grid-row: 1/-1;
  cursor: col-resize;
  /* background-color: #D7D6D6; */
  border-left: 1px solid #EAECF0;
  border-right: 1px solid #EAECF0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 16px;
  max-width: 16px;
  height: 100vh;
  margin-top: -10vh;
  /* transform: translateY(100%); */
  transform: translateY(100%);
  opacity: 0;
  animation: slideIn 1s ease-out forwards;
}

.gutter-col-1 {
  grid-column: 2;
}
.gutter-col-0 {
  grid-column: 1;
}