.bg {
    width: 38px;
    height: 38px;
    border-radius: 9999px;
    display: flex;
    justify-content: center;
    align-items: center;

    img {
        width: 100%;
        height: 100%;
        object-fit: contain;
    }

    img.failed {
        width: 60%;
        height: 60%;
    }
}

.process {
    background-color: #00BB6C;
}

.pending {
    background-color: #F0F0F0;
}

.failed {
    background-color: #f05656;
}
