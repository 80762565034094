.addedWord {
    /* background-color: rgb(218, 255, 193); */
    background-color: rgb(240 255 231);
    color: rgb(5, 165, 5);
}

.removedWord {
    /* background-color: rgb(253, 219, 216); */
    background-color: rgb(255 232 230);
    color: red;
}
.characterWithoutWhiteSpace {
    /* text-decoration: line-through; */
    text-decoration: underline;
}