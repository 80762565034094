.twoDimensionalImageContainer {
  width: 100%;
  /* height: calc(100% - 239px); */
  height: calc(70% - 48px);
  align-items: center;
}
.selectedModelBackgroundColor {
    background-color: #008D9C2F;
}

.referenceGuideAndGroundTruthButtonGroupContainer {
  text-align: end;
  padding-right: 20px;

}
.referenceGuideContainer {
  /* width: 100%; */
  width: 80%;
  height: 100%;
}
.referenceGuideIframe {
  border: unset;
  width: 100%;
  height: 100%;
}

.annotationGroupNameRow {
  cursor: pointer;
}
.annotationGroupNameRow {
  display: flex;
  justify-content: space-between;
}