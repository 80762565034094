.testDriveProgressContainer {
    background-color: #f6fdfe;
    /* border-radius: 8px; */
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    gap: 8px;
    padding: 40px 100px 16px 100px;
    border-bottom: 1px solid rgba(215, 214, 214, 0.4);
    /* margin: -16px -16px; */
    height: 85px;
  }
  
  .testDriveProgressBar {
    height: 3px;
    background-color: #c9d6d7;
    flex: 1;
    border-radius: 4px;
    margin-left: 15px;
    margin-right: 15px;
    min-width: 50px
  }
  .circularProgress{
    stroke-linecap: round;
  }