.segmentationModelAnalytics {

}
.segmentationModelAnalytics .labelAccuracyTableCell {

}
.labelAccuracyContainer {
  display: flex;
}
.labelIntegrityContainer {}

.segmentationModelAnalytics .labelAccuracyAndIntegrityContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-left: 19px;
  cursor: pointer;
}

.segmentationModelAnalytics .totalDataSet,
.segmentationModelAnalytics .totalDataSetValue,
.segmentationModelAnalytics .dataTableColumnHeading,
.segmentationModelAnalytics .labelDataSetValue
{
  visibility: hidden;
}