.coordinatesSection {
  display: flex;
  align-items: center;
  box-sizing: border-box;
  padding-left: 4.5px;
  padding-right: 4.5px;
  margin-right: 10px;
}
.zoomFeatureEnabled .coordinatesSection {
  margin-left: 8px;
}
.coordinatesSection .label {
  font-size: 15px;
}
.coordinatesSection .value {
  font-size: 15px;
  width: 4.5ch;
  color: #6c757d;
}

.imageOptionsToolbar {
  position: sticky;
  top: 0px;
  z-index: 1;
  background-color: white;
}

.focusMode {

}
.focusMode .imageCanvasContainer {
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}
.focusMode .lhsSection {
  margin-top: -58px;
}
.focusMode .addButton {
  margin-bottom: 0px !important;
}
.focusMode .alert {
  top: -75px !important
}

.addButton {
  color: rgb(0, 141, 156) !important;
  border-color: rgb(0, 141, 156) !important;
}
.addButton:hover {
  background-color: white !important;
}