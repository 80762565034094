.counterBtns{
    display: flex;
    gap: 20px;
    align-items: center;
    justify-content: flex-end;
    padding-right: 10px;
}
.actionMessageContainer{
    display: inline-flex;
    justify-content: center;
    align-items: center;
    color: #008D9C;
    font-size: 12px;
    border: 1px solid #008D9C;
    border-radius: 4px;
    padding: 4px 10px;
    gap: 10px;
    transition: opacity 0.3s ease-out;
    opacity: 0;
    position: absolute;
    background-color: #ffff;
}
.toggleBtn{
    display: flex;
    gap: 10px;
    align-items: center;
    cursor: pointer;
}
.active, .toggleBtnIconActive{
    color: #ffff;
    transition: color 0.3s ease-in;
}
.toggleBtnIcon{
    color: "#008D9C";
}
.show{
    opacity: 1;
    z-index: 1;
}
.message{
    font-size: 14px;
}
.toggler{
    background: #008D9C;
    width: 110px;
    height: 25px;
    position: absolute;
    z-index: -1;
    left: 2px;
    border-radius: 4px;
    transition: all 0.3s ease-in;
}
.toggler.sold{
    width: 100px;
    left: 106px;
}
.icon{
   font-size: 18px;
   color: "#008D9C"; 
}
