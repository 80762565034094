.viewButton {
    font-size: 14px;
    line-height: 22px;
    display: flex;
    cursor: pointer;
    align-items: center;
    column-gap: 8px;
    margin-right: 12px;
}

.viewButton, .viewRightArrowIcon {
    color: #008D9C;
    font-family: NunitoSans-Regular;
}

.viewButton .text,
.viewButton .arrow {
    color: inherit;
}

.viewButton .text {
    letter-spacing: 0px;
}

.modelIconRow {
    justify-content: space-between;
}
.modelIconRowLHS {
    /* display: flex; */
    column-gap: 4px;
    width: 78%;
}
.modelIconRowRHS {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: end;
    width: 22%;
}

.modelDeleteHapppening {
    pointer-events: none;
    opacity: 0.5
}

.cardContentRoot {
    cursor: default;
    padding-left: 4px;
    padding-right: 10px;
}
.rootReactFlowEditMode .cardContentRoot {
    cursor: grab;
}
.rootReactFlowEditMode .cardContentRoot:active {
    cursor: grabbing;
}
.viewRightArrowIcon {

}

.modelNameColumn {
    margin : 0px 5px 0px; 
    height: 80px;
}
