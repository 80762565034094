.state__body {
    min-width : 30%;
    height: 90%;
    margin: 3%;
    display: flex;
    position: relative;
    flex-direction: column;
    justify-content: space-between;
}

.state__stick {
    width: 8px;
    left: 15px;
    top: 2.5%;
    bottom: 2.5%;
    height: 95%;
    position: absolute;
    z-index: 0;

    .done {
        background: #00BB6C;
        transition: height 1s ease;
    }
    
    .pending {
        background: #F0F0F0;
        transition: height 1s ease;
    }
}
