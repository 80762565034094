.forTextOne {
}
.forTextTwo {
}
.TextDiffSectionComponentRoot {
}
.forTextOne.TextDiffSectionComponentRoot {
  background-color: #f6fdf8;
}
.forTextTwo.TextDiffSectionComponentRoot {
  background-color: #fff8ee;
}
.character {
}
.characterHasDifferenceInText {
}
.forTextOne .characterHasDifferenceInText {
  /* background-color: #92f39a; */
  background-color: #ffe6e6;
  color: red;
}
.forTextTwo .characterHasDifferenceInText {
  /* background-color: #f5ac77; */
  background-color: #ffe6e6;
  color: red;
}

.audioPlaybackHighlightColor {
  /* background-color: yellow; */
  background-color: rgb(210 245 210);
}