.aiAssistantTooltip {
  background-color: white;
  color: black;
  padding: 20px;
  /* box-shadow: rgb(0 0 0 / 15%) 0px 0px 20px 0px; */
  box-shadow: 5px 5px 20px #028d9a1a;
  /* fontSize: 16px; */
  /* border: 1px solid #028D9A1A; */
  border: 1px solid #c7cfd5;
  border-radius: 10px;
}
.aiAssistantTooltipArrow::before {
  background: white !important;
  /* border: 1px solid #028D9A1A */
  border: 1px solid #c7cfd5;
}
.aiAssistantConfirmationMessage {
  font-size: 16px;
  line-height: 23px;
  color: #707070;
  padding-left: 20px;
  padding-right: 20px;
  text-align: center;
}
.aiAssistantConfirmationFooter {
  text-align: center;
  margin-top: 12px;
}
.aiAssistantConfirmationHeader {
  text-align: end;
  margin-bottom: -11px;
}
.aiAssistantConfirmationPopupCloseIcon {
  color: #b4b2b2;
  font-size: 16px;
  cursor: pointer;
  margin-bottom: 14px;
  margin-top: -16px;
  margin-right: -13px;
}

.rootBookTranslationModelType {

}
.dataTableHeadCell {}
.rootBookTranslationModelType .dataTableHeadCell {
  /* vertical-align: text-top; */
  vertical-align: top;
}
.bookTranslationLastModifiedHeading,
.rootBookTranslationModelType .dataTableHeadCell,
.rootBookTranslationModelType .tableSortLabel {
  color: rgb(152, 166, 179) !important;
}

.rootBookTranslationModelType .confidenceScoreTableSortLabel {
  align-items: flex-start;
}

.tagTableHeadCellBox {
}
.rootBookTranslationModelType .tagTableHeadCellBox {
  align-items: flex-start;
}

.bookTranslationLastModifiedHeading{
  display: flex;
  flex-direction: row;
  /* align-items: baseline; */
  margin-bottom: 10px;
}

.bookTranslationTableHeadMoreButton{
  padding-top: 2px;
}

.bookTranslationModelHeadingP{
  margin-bottom: 0px;
}

.mispreditedParagraphTooltip{
  background-color: white;
  box-shadow: 5px 5px 20px #028d9a1a;
  border: 1px solid #c7cfd5;
}

.mispreditedParagraphTooltipArrow::before{
  background-color: white;
  color: white;
  border: 1px solid #c7cfd5;
}

.arrowDown {
  position: relative;
}

.arrowDown::before {
  content: "";
  display: block;
  position: absolute;
  width: 12px;
  height: 12px;
  bottom: -6px;
  left: calc(20% - 6px);
  transform: rotate(45deg);
  background-color: white;
}

.highlightMispredictedParagraph{
  background-color:#EF8A8A;
  padding:7px 15px;
  border-radius:16px;
  color:white ;
  font-size:13px;
  height: 32px;
  border:none
}
.highlightParagraph{
  background-color:#F3F4F6;
  padding:7px 15px;
  border-radius:16px;
  color:rgba(0, 0, 0, 0.87);
  font-size:13px;
  height: 32px;
  border:none
} 

.reactflowContainer{
  overflow: scroll !important;
  height: 30vh !important;
}
/* With below change, user will always be able to see scrollbar on reactflow for book translation sentences */
.reactflowContainer::-webkit-scrollbar {
/* ::-webkit-scrollbar { */
  -webkit-appearance: none;
  width: 7px;
}
.reactflowContainer::-webkit-scrollbar-thumb {
/* ::-webkit-scrollbar-thumb { */
  border-radius: 4px;
  background-color: rgba(0, 0, 0, .5);
  box-shadow: 0 0 1px rgba(255, 255, 255, .5);
}


@media (min-width: 1500px) {
  .reactflowContainer{
    height: 40vh !important;
  }
}


.tableSortLabel {
}

.bookTranslationChangesActionBtns{
  display: flex;
  flex-direction: row;
  gap: 10px;
}

.editLinePairingBtn{
  color: #0193a3;
  border: 2px solid #0193a3;
  border-radius: 2px;
  padding: 5px 10px;
  background-color: #ffffff1a;
}

.approveBtn:hover{
  cursor: pointer;
}
.translateToOriginalLanguage{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.eyeIcon {
  margin: 6px 0px;
}

.eyeIcon:hover{
  cursor: pointer;
}

.contextCursor:hover{
  cursor: context-menu;
}