.card{
    width: 277px;
    height: 470px;
    padding: 20px;
    display: flex;
    flex-direction: column;
    gap: 15px;
}

.annotationCard{
    width: 380px;
    height: 450px;
    padding: 20px;
    display: flex;
    flex-direction: column;
    gap: 8px;
    margin: 15px 25px;
}
.rejectedCard{
    height: 530px;
}
.confidenceContainer{
    background: #F4F5F7;
    height: 46px;
    border-radius: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 14px;
}
.actionBtns{
    position: relative;
    top: 50px;
}
.annotationbuttons{
    margin: 0px 60px;
}
.footer{
    display: flex;
    justify-content: space-between;
    margin: 0 5px;
    margin-top: 35px;
}
.expiredText{
    color: #FF625C;
    font-size: 14px;
}
.approvedText{
    color: #28a745;
    font-size: 14px;
}
.detailsLink{
    color: #008D9C;
    border-bottom: 1px solid currentcolor;
    cursor: pointer;
    font-size: 14px;
}
.drawerOpen{
    border: 2px solid #008D9C;
    box-shadow: 8px 8px 36px #028D9A33;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border-radius: 5px;
    opacity: 1;
}

.cardShadow{
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 5px 5px 20px #028D9A1A;
    border-radius: 5px;
    opacity: 1;
}

.fadedView {
    pointer-events: none;
    opacity: 0.5;
}

.modelPopupFadedView{
    pointer-events: none;
    opacity: 0.5;
}

.imageContainer {
    display: flex; 
    align-items: center; 
    justify-content: center; 
    width: 231px; 
    height: 231px;
}