.integrityConfidenceBandsTable tr:nth-child(even) {
    background-color: rgb(238 254 255);
}

.integrityConfidenceBandsTable {
    margin-right: 12px;
}

.integrityConfidenceBandsTable th {
    color: #838C95;
}

.dataPointsTableHeader {
    text-align: right;
    padding-left: 20px;
}

.goodConfidenceScoreTableHeader {
    text-align: right;
    padding-left: 20px;
}

.goodConfidenceScoreTableHeader {
    text-align: right;
    padding-left: 20px;
}

.badConfidenceScoreTableHeader {
    text-align: right;
    padding-left: 20px;
}

.badConfidenceScoreTableHeader {
    text-align: right;
    padding-left: 20px;
}


.confidenceBandTableCell {
    padding: 6px;
    color: #838C95;
}

.dataPointsTableCell {
    color: #0045FF;
    text-align: right;
}

.goodConfidenceScoreTableCell {
    color: #008D9C;
    cursor: pointer;
    text-align: right;
    text-decoration: underline;
}

.badConfidenceScoreTableCell {
    color: #D6100F;
    cursor: pointer;
    text-align: right;
    text-decoration: underline;
}