.contentContainer{
    display: flex;
}
.imageAndTools{
    flex:2;
    margin-right: 25px;
}
.labelAndActions{
    flex: 1;
    flex-shrink: 0;
}
.imageContainer{
    width: 100%;
    background-color: green;
    display: flex;
    justify-content: center;
    align-items: center;
    height: calc(100vh - 320px);
    position:relative;
    border-radius: 10px;
    overflow: hidden;
}
.cropImageContainer{
    width: 100%;
    height: 100%;
    background-color: transparent;
}
.image{
    width: 100%;
    height: 100%;
    object-fit:contain;
    border-radius: 10px;
}
.iconTray{
    display: flex;
    margin-top:10px;
    justify-content: space-between;
    align-items: center;
}
.subActionIcon{
    display:  flex;
    justify-content: center;
    align-items: center;
}
.iconContainer{
    display: flex;
    margin-left: 5px;
    margin-right: 5px;
    justify-content: center;
    background-color:#CCE8EB;
    border-radius: 5px;
    align-items: center;
    padding:10px;
    cursor: pointer;
}
.activeIcon{
    background-color: #008D9C;
}
.actionBtnContainer{
    margin-top: 10px;
    display: flex;
}
.labeledInput{
    display: flex;
    margin-bottom: 10px;
}
.label{
    flex:1
}
.textBox{
    flex:2
}
.imageActions{
    display: flex;
    align-items: center;
    justify-content: space-around;
    margin-top: 7px;
    margin-bottom: 7px;
}
.sliderField{
    width: 60px;
    margin-left: 15px;
}
.slider{
    margin-left: 30px;
    margin-right: 30px;
}
.cancelBtn{
    border-color: red;
    color:red
}
.cropContainer{
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color:white;
    z-index: 999;
}
.controls{
    width: 100%;
    position: absolute;
    bottom:10px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.controlSave{
    width: 30%;
}
.controlCancel{
    margin-right: 25px;
    background-color: red !important;
    color:white;
    width: 30%;
}

.hide{
    visibility: hidden;
}
.aspectRatioBtn{
    height:45px;
    background-color: #CCE8EB;
    margin-left: 10px;
    box-shadow: none !important;
    color:#008D9C;
}
.aspectRatioBtnActive{
    height:45px;
    background-color: #008D9C;
    margin-left: 10px;
    box-shadow: none !important;
    color:white;
}
.aspectRatioBtnActive:hover{
    background-color: #008D9C;
}
.aspectRatioBtn:hover{
    background-color: #CCE8EB;
}
.aspectRatioContainer{
    display: flex;
    align-items: center;
    padding: 15px;
    padding-left: 5px;
}
.customAspectRatio{
    display: flex;
    align-items: center;
}
.customAspectRatioInput{
    width: 100px;
    margin-right:10px;
    margin-bottom: 15px;
}
.crossLabel{
    margin-right:10px;
    margin-bottom: 15px;
}
.activeIcon{
    color:white !important;
}
.disabled{
    background-color: #DEDEDE;
}