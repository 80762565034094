.MuiTabs-root {
    position: sticky;
    // top: 0px;
    // z-index: 1;
    background-color: #f0f5fc !important;
    // background-color:black !important;
}
.MuiPaper-elevation0{
    position: sticky;
    //  top: 46px;
    // z-index: 1;
    background-color: #e7edf4 !important; 
    margin-top: -16px;
    margin-bottom: -16px;
}
/* .MuiTableRow-root .MuiTableRow-head{
    position: sticky;
    top: 46px;
    z-index: 1;
    background-color: #f0f5fc !important; 
}
.header{
    position: sticky !important;
    top: 0px !important;
    z-index: 1 !important;
    background-color: #f0f5fc !important; 
} */

.test-data-set-container {
    .MuiFormLabel-root {
        text-align: left;
    font: normal normal normal 12px/15px Nunito Sans;
    letter-spacing: 0.22px;
    color: #707070;
    text-transform: uppercase;
    opacity: 1;
    }
}

@keyframes CardBorderAnimation {
    0% {
        background: linear-gradient(to right, #7CD74B 99.99%, transparent), linear-gradient(to bottom, #7CD74B 99.99%, transparent), linear-gradient(to right, #7CD74B 99.99%, transparent), linear-gradient(to bottom, #7CD74B 99.99%, transparent);
        background-repeat: no-repeat;
        background-size: 99% 8px, 8px 99%, 99% 8px, 8px 99%;
        background-position: 0 0, 100% 0, 100% 100%, 0 100%
    }
    
    25% {
        background: linear-gradient(to right, #7CD74B 99.99%, transparent), linear-gradient(to bottom, #7CD74B 99.99%, transparent), linear-gradient(to right, #7CD74B 99.99%, transparent), linear-gradient(to bottom, #7CD74B 99.99%, transparent);
        background-repeat: no-repeat;
        background-size: 99% 8px, 8px 99%, 99% 8px, 8px 0;
        background-position: 0 0, 100% 0, 100% 100%, 0 100%;
    }
    
    50% {
        background: linear-gradient(to right, #FF9030 99.99%, transparent), linear-gradient(to bottom, #FF9030 99.99%, transparent), linear-gradient(to right, #FF9030 99.99%, transparent), linear-gradient(to bottom, #FF9030 99.99%, transparent);
        background-repeat: no-repeat;
        background-size: 99% 8px, 8px 99%, 0 8px, 8px 0;
        background-position: 0 0, 100% 0, 100% 100%, 0 100%;
    }
    
    75% {
        background: linear-gradient(to right, #FF625C 99.99%, transparent), linear-gradient(to bottom, #FF625C 99.99%, transparent), linear-gradient(to right, #FF625C 99.99%, transparent), linear-gradient(to bottom, #FF625C 99.99%, transparent);
        background-repeat: no-repeat;
        background-size: 99% 8px, 8px 0, 0 8px, 8px 0;
        background-position: 0 0, 100% 0, 100% 100%, 0 100%;
    }
    
    100% {
        background: linear-gradient(to right, #FF625C 99.99%, transparent), linear-gradient(to bottom, #FF625C 99.99%, transparent), linear-gradient(to right, #FF625C 99.99%, transparent), linear-gradient(to bottom, #FF625C 99.99%, transparent);
        background-repeat: no-repeat;
        background-size: 0 8px, 8px 0, 0 8px, 8px 0;
        background-position: 0 0, 100% 0, 100% 100%, 0 100%;
    }
}

@keyframes CardBorderAnimation2 {
    0% {
        background: linear-gradient(to right, #7CD74B 99.99%, transparent), linear-gradient(to bottom, #7CD74B 99.99%, transparent), linear-gradient(to right, #7CD74B 99.99%, transparent), linear-gradient(to bottom, #7CD74B 99.99%, transparent);
        background-repeat: no-repeat;
        background-size: 99% 8px, 8px 99%, 99% 8px, 8px 99%;
        background-position: 0 0, 100% 0, 100% 100%, 0 100%
    }

    25% {
        background: linear-gradient(to right, #7CD74B 99.99%, transparent), linear-gradient(to bottom, #7CD74B 99.99%, transparent), linear-gradient(to right, #7CD74B 99.99%, transparent), linear-gradient(to bottom, #7CD74B 99.99%, transparent);
        background-repeat: no-repeat;
        background-size: 99% 8px, 8px 99%, 99% 8px, 8px 0;
        background-position: 0 0, 100% 0, 100% 100%, 0 100%;
    }

    50% {
        background: linear-gradient(to right, #FF9030 99.99%, transparent), linear-gradient(to bottom, #FF9030 99.99%, transparent), linear-gradient(to right, #FF9030 99.99%, transparent), linear-gradient(to bottom, #FF9030 99.99%, transparent);
        background-repeat: no-repeat;
        background-size: 99% 8px, 8px 99%, 0 8px, 8px 0;
        background-position: 0 0, 100% 0, 100% 100%, 0 100%;
    }

    75% {
        background: linear-gradient(to right, #FF625C 99.99%, transparent), linear-gradient(to bottom, #FF625C 99.99%, transparent), linear-gradient(to right, #FF625C 99.99%, transparent), linear-gradient(to bottom, #FF625C 99.99%, transparent);
        background-repeat: no-repeat;
        background-size: 99% 8px, 8px 0, 0 8px, 8px 0;
        background-position: 0 0, 100% 0, 100% 100%, 0 100%;
    }

    100% {
        background: linear-gradient(to right, #FF625C 99.99%, transparent), linear-gradient(to bottom, #FF625C 99.99%, transparent), linear-gradient(to right, #FF625C 99.99%, transparent), linear-gradient(to bottom, #FF625C 99.99%, transparent);
        background-repeat: no-repeat;
        background-size: 0 8px, 8px 0, 0 8px, 8px 0;
        background-position: 0 0, 100% 0, 100% 100%, 0 100%;
    }
}
