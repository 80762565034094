.two-dimensional-image .two-dimensional-image {
    &__canvasContainerIfSideDrawer {
        position: sticky;
        top: 0px;
        z-index: 5;
        padding-left: 12px;
        padding-right: 12px;
        padding-top: 10px;
        background-color: #e7edf3;
    }
    &__addAnnotationButtonRowForSideDrawerScenario {
		display: flex;
		justify-content: space-between; 
		min-width: 370px;
        padding: 0px 10px;
    }
    &__addAnnotationButtonRowForDialogScenario {
		min-width: 390px;
    }
    &__saveAnnotationButtonRowForDialogScenario {
        position: sticky !important;
        bottom: 0;
        background-color: white;
    }
    &__actionSectionContainerForDialogScenario {
        position: sticky !important;
        // bottom: 36px;
        // bottom: 45px;
        bottom: 51px;
        background-color: white;
    }
    &__add-button {
        small {
            padding-left: 5px;
        }
    }

    &__image-canvas-container {
        // background: rgb(246, 246, 246);
    }

    &__label-button {
        color: #000;
        width: 100px;
        &:hover,
        &:active,
        &:focus {
        	text-decoration: none;
        }
    }

    &__addScrollbarForDialogScenario{
        overflow: auto;
        max-height: 510px;
        padding-right: 10px;
    }
}

.displayNone {
    display: none !important;
}
.annotation-list-whenCoPilotActivePageEditAnnotationScenario {
    height: 135px !important;
}
.justify-content-left {
    justify-content: left !important
}
.justify-content-space-between {
    justify-content: space-between !important
}
.padding-top-0 {
    padding-top: 0px !important;
}
.padding-bottom-0 {
    padding-bottom: 0px !important;
}
.alertForEditAnnotation {
    right:0;
    top:'-50%';
}
.alert{
    left:0;
    right:0;
    top:'-50%';
}

.annotations-list{
    margin-top: 420px;
}