.ThinkingComponentContainer {
}
.ThinkingComponentHeader {
    justify-content: unset !important;
    gap: 12px
}
.ThinkingComponentHeaderBackground {
    background-color: transparent !important;
}
.markDownContentWhenExplainabilityPresent {
    /* margin-top: 4px */
}
.explainabilityParent {}
.explainabilityParent img {
    width: 100%;
}