.hearderContainer {
    display: flex;
    flex-direction: column;
    /* border-bottom: 1px solid #E0E0E0 */
    padding: 12px;
    padding-top: 1.5rem;
    gap: 1rem;
    padding: 2rem 1rem 1rem 1rem;
    border-bottom: solid 1px lightgrey;
    position: sticky;
    top: 0;
    z-index: 100;
    background-color: aliceblue;
}

.hearderHeading {
    /* font-family: 'Inter'; */
    font-size: 20px;
    font-weight: 500;
    line-height: 24.2px;
    text-align: left;
}

/* landing page css */

.ProjectTypeListContainer {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    padding: 1rem;
    max-width: 95%;
}

.ProjectTypeContainer {
    width: 100%;
    height: 250px;
    gap: 16px;
    border-radius: 32px;
    display: flex;
    flex-direction: column;
    gap: 24px;
    padding: 35px 36px 35px 36px;
    background-image: linear-gradient(97.41deg, #088B99 0%, #02AEC1 30.97%);
    position: relative;
}

.projectTypeHeading {
    font-size: 32px;
    font-style: italic;
    font-weight: 600;
    line-height: 38.73px;
    text-align: left;
    color: #FFFFFF;
}

.subTasksContainer {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 1.5rem;
}

.subTasksChip {
    color: white;
    border-color: white !important;
    /* background-color: transparent; */
}

.subTasksChip:hover {
    background-color: #ffffff !important;
    color: rgb(19, 19, 19) !important;
}

.generalPurposeType {
    background: rgba(89, 187, 158, 0.10) !important;
    color: #59BB9E !important;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    text-transform: capitalize;
}

.specificPurposeType {
    background: rgba(34, 100, 205, 0.10) !important;
    color: #2264CD !important;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    text-transform: capitalize;
}

.taskBasedBgImage {
    position: absolute;
    right: 5%;
    top: 0
}

.sideDrawerContainer {
    background: #F9FAFB;
    box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.12);
    overflow-x: hidden;
    height: 100%;
    width: 300px;
    padding: 20px 16px;
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.sideDrawerFotterContainer {
    background: #F9FAFB;
    height: 80px;
    display: flex;
    align-items: center;
    position: fixed;
    bottom: 0;
    /* border: 2px solid red; */
    width: inherit;
    padding: 22.98px 56px 23.02px 40px
}

.filterFieldContainer {
    display: flex;
    padding: 10px 12px;
    flex-direction: column;
    align-items: flex-start;
    align-self: stretch;
    border-radius: 6px;
    border: 1px solid #D1D5DB;
    gap: 1rem;
}

/* view-model-page-section */
.viewModelPageContainer {
    /* border: 1px solid green; */
    height: 100%;
}

.rowFlex {
    display: flex;
    flex-direction: row;
}

.columnFlex {
    display: flex;
    flex-direction: column;
}

.viewModelSubHeading {
    color: #212121;
    /* font-size: 24px; */
    font-style: normal;
    font-weight: 600 !important;
    line-height: 28px;
    font-family: Inter !important;
}

.Customchip {
    padding: 3.5px 12px;
    border-radius: 6px !important;
    background: rgba(131, 140, 149, 0.08) !important;
    color: #838C95 !important;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: 150%;
    /* 18px */
}

.viewModelFooter {
    border-top: 0.5px solid var(--Text-Disabled, #A6A6A6);
    background: var(--Grays-White, #FFF);
    padding: 8px 10px;
    /* height: 58px; */
    justify-content: flex-end;
    align-items: center;
    gap:1rem;
    position: fixed;
    bottom: 0;
    padding-right: 2rem;
    width: -webkit-fill-available;
}
.homepageChatViewModelHeaderCss{
    border: 0;
    padding: 0;
    justify-content: flex-start;
    position: inherit;
    margin-bottom: 1rem;
}

.CustomRoundedDiv {
    border-radius: 12px;
    background: #F7F8F8;
    padding: 16px 32px 26px 32px;
    /* gap: 24px; */
}
.forksAccuracyIntegrityContainer {
    border-radius: 4px;
    border: 1px solid #ABD0D6;
    background: #F2F6F7;
    gap: 16px;
    padding: 8px;
}