.focusMode {

}

.focusMode .closeBtn {
  top: 10px;
  right: 10px;
}

.dialogPaper {
  max-width: unset;
  width: 100%;
}
.dataName {
  color: #8e969e
}