.dialogContainer {
  overflow: visible;
  /* height: "752.41px"; */
  height: 100%;
}
.annotationGettingDraggedByUser {}
.annotationViewMode {}
@media (min-height: 911px) {
  .dialogContainer {
    /* max-height: 800px; */
    max-height: 849px;
  }
}

.videoAnnotationTime {
  position: absolute;
  justify-content: space-between;
  visibility: hidden;
  /* transition: 0.2s 0.4s; delay of few second on hover off */
}
.annotationTag {
  visibility: hidden;
}
.annotationTag,
.videoAnnotationTime 
{
   /* transition: 0.2s 0.4s; delay of few second on hover off */
  transition: 0.2s 0.2s; /* delay of few second on hover off */
}

.annotationGettingDraggedByUser .annotationTag,
.annotationGettingDraggedByUser .videoAnnotationApproveIcon,
.annotationGettingDraggedByUser .videoAnnotationRejectIcon,
.annotationGettingDraggedByUser .videoAnnotationTime,
.annotationViewMode .annotationTag,
.annotationViewMode .videoAnnotationTime
.annotationViewMode .videoAnnotationApproveIcon
.annotationViewMode .videoAnnotationRejectIcon
{
  transition: none !important;
}

.doNotDelayMouseHoverShowTransition.annotationTag,
.doNotDelayMouseHoverShowTransition.videoAnnotationTime {
  /* transition: none; */
}

.annotationSection:hover .annotationTag {
  visibility: visible;
  /* transition-delay: 0.2s; */
  transition: 0.3s 0.3s;
}
.annotationSection:hover .videoAnnotationTime {
  visibility: visible;
  /* transition-delay: 0.2s; */
  transition: 0.3s 0.3s;
}

.videoAnnotationApproveIcon,
.videoAnnotationRejectIcon {
  position: absolute;
  visibility: hidden;
  transition: 0.2s 0.2s; 
}

.videoAnnotationRejectIcon {
  transform: rotate(180deg);
}

.annotationSection:hover .videoAnnotationApproveIcon {
  visibility: visible;
  transition: 0.3s 0.3s;
}

.annotationSection:hover .videoAnnotationRejectIcon {
  visibility: visible;
  transition: 0.3s 0.3s;
}

.videoAnnotationTime:hover
.visibilityHiddenForStartEndTime {
  visibility: hidden;
}

.visibilityHidden {
  visibility: hidden;
}

.deleteAnnotationConfirmationPopup {}

.deleteAnnotationConfirmationPopupTooltip {
  background-color: white;
  color: black;
  padding: 20px;
  /* box-shadow: rgb(0 0 0 / 15%) 0px 0px 20px 0px; */
  box-shadow: 5px 5px 20px #028d9a1a;
  /* fontSize: 16px; */
  /* border: 1px solid #028D9A1A; */
  border: 1px solid #c7cfd5;
  border-radius: 10px;
}
.deleteAnnotationConfirmationPopupTooltipArrow::before {
  background: white !important;
  /* border: 1px solid #028D9A1A */
  border: 1px solid #c7cfd5;
}
.deleteAnnotationConfirmationPopupMessage {
  font-size: 15px;
  line-height: 23px;
  color: #707070;
  padding-left: 20px;
  padding-right: 20px;
  text-align: center;
}
.deleteAnnotationConfirmationPopupFooter {
  text-align: center;
  margin-top: 12px;
}
.deleteAnnotationConfirmationPopupHeader {
  text-align: end;
  margin-bottom: -11px;
}
.deleteAnnotationConfirmationPopupCloseIcon {
  color: #b4b2b2;
  font-size: 16px;
  cursor: pointer;
  margin-bottom: 14px;
  margin-top: -16px;
  margin-right: -13px;
}

.infoRow {
  margin-left: 46px;
  display: flex;
  /* align-items: baseline; */
  margin-top: 39px;
  column-gap: 50px;
}
.infoRow .annotationInfoText {
  display: flex;
  align-self: center;
}
.infoRowLHS {
  display: flex;
  column-gap: 10px;
}
.infoRowRHS {
  display: flex;
  column-gap: 18px;
  align-items: center;
}
.incorrectAnnotationWhenNotHovering,
.infoRow .incorrectAnnotationColour
 {
  opacity: 0.4;
  background-color: red;
}
.correctAnnotationWhenNotHovering,
.infoRow .correctAnnotationColour
{
  opacity: 0.8;
  background-color: #008D9C;
}
.infoRow .annotationColourLegend {
  display: flex;
  column-gap: 6px;
  align-items: center;
}
.infoRow .annotationColour {
  width: 26px;
  height: 24px;
  border-radius: 4px;
}
.infoRow .correctAnnotationColour {
  /* opacity: 53%; */
}
.infoRow .incorrectAnnotationColour {
  /* opacity: 53%; */
}

.infoRow .annotationInfoText {
  /* min-width: 358px; */
}

.infoRow .annotationInfoText,
.infoRow .annotationLabel
{
  color: #98A6B3;
  font-size: 14px;
  line-height: 19px;
  font-family: Nunito Sans
}

.aiAnnotationsCountSection {
  display: flex;
  column-gap: 80px;
}
.aiAnnotationsCountSection_detectedSection,
.aiAnnotationsCountSection_expertFeedbackSection {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  row-gap: 14px;
}
.aiAnnotationsCountSection .countSectionLabel {
  font-family: Nunito Sans;
  color: #98A6B3;
  font-size: 16px;
  line-height: 22px;
}
.aiAnnotationsCountSection .countSectionCount {
  width: 50px;
  height: 40px;
  /* border: 1px solid #028D9A1A; */
  border: 1px solid #6f7070;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  border-radius: 5px;
  align-items: center;
  justify-content: center;
  display: flex;
}
.visibilityHidden {
  visibility: hidden !important;
}