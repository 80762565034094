.wrapper{
    margin: -15px;
    height: 100vh;
    background: #ffff;
    overflow-y: auto;
}
.remark_container{
    position: sticky;
    top: 0;
    background: #ffff;
    padding: 20px;
    display: flex;
    flex-direction: column;
    gap: 20px;
    margin: auto;
    max-width: 325px;
    overflow: hidden;
    z-index: 1;
}
.confidenceContainer{
    background: #F4F5F7;
    height: 46px;
    border-radius: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.resourceInfo{
    padding: 30px;
    padding-left: 40px;
    font-size: 14px;
}
.resourceInfo p {
    display: inline-flex;
}
.resourceText{
    min-width: 170px;
    color: #838C95;
}
.remarkInputContainer{
    margin-top: 30px;
}
.actionBtns{
    position: relative;
    top: 50px;
}
.padding-top-0 {
    padding-top: 0px !important;
}
.fadedView {
    pointer-events: none;
    opacity: 0.5;
}

.imageContainer {
    display: flex; 
    align-items: center; 
    justify-content: center; 
    width: 301px; 
}