.highlightTrimTextInResourceText {
  animation: highlightTrimTextInResourceText 0.5s ease-in-out infinite alternate;
}


@-webkit-keyframes highlightTrimTextInResourceText {
  from {
    transition: all;
  }
  
  to {
    border: none;
  }
}
@keyframes highlightTrimTextInResourceText {
  from {
    border-color: black;
  }
  
  to {
    border:none;
  }
}