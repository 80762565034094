.layout {
    border-radius: 12px;
    border: 1px solid #E1E6E6;
    background: #FFF;
    display: flex;
    width: 85%;
    height: 85%;
    margin: auto;
    align-items: center;
}

.ruler {
    width: 1px;
    height: 85%;
    background: #E1E6E6;
}