.focusMode {}
.imageCopilotType {}
.videoCopilotType {}
.audioCopilotType {}
.textCopilotType {}
.evaluateAnsScoreCopilotType {}

.showingReferenceContextInformation {}
.approveRejectCardActions {}
.itemCardPaperWhenCopilotTypeBestAnswer {
  /* width: 53vw */
  width: 58vw
}
.bestAnsCopilotType {}

.bestAnsCopilotType .approveRejectCardActions {

}

.copilotDataImage {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.evaluateAnsCopilotType {}

.imageContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 401px;
    height: 401px;
    margin: auto auto;
    position: relative;
  }

  .imageZoomIcon{


    position: absolute;
    bottom: 0;
    right: 0;
    margin: 10px;


  }
  .imageZoomIcon:hover{
    cursor: pointer;
  }
  .zoomCard{
    position: absolute;
    height: 100%;
    width:100%;
    top: 30px;
}
.audioCopilotType .imageContainer,
.textCopilotType .imageContainer {
  width: 100%;
  height: 100%;
}

.audioCopilotType .imageContainer {
  margin: unset;
  height: unset;
}

.textCopilotType .imageContainer {
  width: 100%;
  height: 100%;
}

.cardImageColumnContainerWhenCopilotTypeImage {
    /* background-color: #d4e8ea; */
    padding: 20px;
    display: grid;
    gap: 20px;
}
.imageCopilotType .cardImageColumnContainerWhenCopilotTypeImage,
.audioCopilotType .cardImageColumnContainerWhenCopilotTypeImage,
.textCopilotType .cardImageColumnContainerWhenCopilotTypeImage,
.videoCopilotType .cardImageColumnContainerWhenCopilotTypeImage {
  width: 100%;
  height: 100%;
}

.audioCopilotType .cardImageColumnContainerWhenCopilotTypeImage {
  display: flex;
  flex-direction: column;
}

.imageCopilotType .timerAnimation {
  width: 100%;
  height: 100% !important;
}

.itemCardPaper {

}
.imageCopilotType .itemCardPaper,
/* .audioCopilotType .itemCardPaper, */
.textCopilotType .itemCardPaper,
.videoCopilotType .itemCardPaper {
  /* width: 40vw !important; */
  width: 450px;
  /* height: 100%; */
  height: 75vh !important;
}

.audioCopilotType .itemCardPaper {
  width: 100%;
  min-width: 45vw;
}

.detailsViewOpened.imageCopilotType .itemCardPaper,
/* .detailsViewOpened.audioCopilotType .itemCardPaper, */
.detailsViewOpened.videoCopilotType .itemCardPaper,
.detailsViewOpened.textCopilotType .itemCardPaper {
  width: 749px;
}

.differentColorCardParent {}

.imageCopilotType .differentColorCardParent,
.audioCopilotType .differentColorCardParent,
.textCopilotType .differentColorCardParent,
.videoCopilotType .differentColorCardParent {
  width: 100%;
  height: 100%;
}

.InformationHeading {
  color: #008D9C;
  font-size: 14px;
}

.evaluateAnsScoreCopilotType .InformationHeading {
  font-weight: bold;
  text-align: left;
  color: black;
  font-size: 16px;
  /* padding-left: 10px; */
}

.referenceContextContainer {
  display: flex;
  flex-direction: column;
  gap: 20px;  
  overflow: auto;
}

.evaluateAnsScoreCopilotType .referenceContextContainer {
  height: 90%;
}

.referenceContext {
  background-color: #d3d3d34f;
  padding: 5px;
  border-radius: 10px;
  white-space: break-spaces;
}
.questionRow {
  overflow: auto;
  /* height: 73px; */
  /* height: 61px; */
  height: 53px;
  max-height: 55px;
  /* margin-bottom: 12px; */
  font-size: 18px;
}
.question::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 7px;  
}
.question::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: rgba(0, 0, 0, .5);
  box-shadow: 0 0 1px rgba(255, 255, 255, .5);  
}
.showingReferenceContextInformation .itemCardPaperWhenCopilotTypeBestAnswer {
  width:  80vw
}

.bestAnswerCopilotResourceContent {
  height: 50vh
}
.focusMode .bestAnswerCopilotResourceContent {
  height: 68vh
}
.bestAnswerCopilotResourceContent .question {

}
.bestAnswerCopilotResourceContent .answersContainer {
  display: flex;
  /* height: calc(100% - 113px); */
  height: 82%;
  column-gap: 20px;
  overflow: auto;
}
.evaluateAnsCopilotType .bestAnswerCopilotResourceContent .answersContainer,
.evaluateAnsScoreCopilotType .bestAnswerCopilotResourceContent .answersContainer
 {
  display: flex;
  /* height: calc(100% - 113px); */
  height: 80%;
  column-gap: 20px;
  overflow: auto;
}
.focusMode.evaluateAnsCopilotType .bestAnswerCopilotResourceContent .answersContainer,
.focusMode.evaluateAnsScoreCopilotType .bestAnswerCopilotResourceContent .answersContainer
{
  height: 92%;
}
.bestAnswerCopilotResourceContent .ansColumn {
  width: 30%;
  min-width: 261px;
  overflow: auto;
  cursor: pointer;
}
.bestAnswerCopilotResourceContent .ansColumn::-webkit-scrollbar,
.bestAnswerCopilotResourceContent .scoresLabelAndValueContainer::-webkit-scrollbar,
.bestAnswerCopilotResourceContent .ansText::-webkit-scrollbar,
.questionRow::-webkit-scrollbar
 {
  -webkit-appearance: none;
  width: 7px;
}

.bestAnswerCopilotResourceContent .ansColumn::-webkit-scrollbar-thumb,
.bestAnswerCopilotResourceContent .scoresLabelAndValueContainer::-webkit-scrollbar-thumb,
.bestAnswerCopilotResourceContent .ansText::-webkit-scrollbar-thumb,
.questionRow::-webkit-scrollbar-thumb
 {
  border-radius: 4px;
  background-color: rgba(0, 0, 0, .5);
  box-shadow: 0 0 1px rgba(255, 255, 255, .5);
}
.evaluateAnsCopilotType .bestAnswerCopilotResourceContent .ansColumn
{ 
  width: 80%;
}
.evaluateAnsScoreCopilotType .bestAnswerCopilotResourceContent .ansColumn
{ 
  width: 50%;
}


.ansHeading,
.scoresHeading
{
  font-weight: bold;
  text-align: left;
  padding-left: 10px;
}

.chooseBestSuitableAns {
  font-size: 23px;
  margin-bottom: 10px;
}

.ansColumn .isSelected {
  background-color: #008d9c24;
  border-radius: 10px;
}
.bestAnswerCopilotResourceContent .ansSection {
  /* margin-top: 20px; */
  height: 60%;
  margin-bottom: 10px;
  overflow: auto;
}
.evaluateAnsCopilotType .bestAnswerCopilotResourceContent .ansSection {
  height: 95%;
}
.evaluateAnsScoreCopilotType .bestAnswerCopilotResourceContent .ansSection {
  height: 95%;
}
.bestAnswerCopilotResourceContent .ansText {
  height: 90%;
  overflow: auto;
  padding: 10px;
}
.ansText {
  white-space: break-spaces;
}
.bestAnswerCopilotResourceContent .scoresSection {
  height: 23%;
  overflow: auto;
}
.evaluateAnsScoreCopilotType .bestAnswerCopilotResourceContent .scoresSection {
  height: 100%;
  overflow: auto;
}
.focusMode .bestAnswerCopilotResourceContent .scoresSection {
  /* height: 40% */
  height: 29%
}
.evaluateAnsCopilotType .bestAnswerCopilotResourceContent .scoresSection,
.evaluateAnsScoreCopilotType .bestAnswerCopilotResourceContent .scoresSection
{
  height: unset;
}
.evaluateAnsScoreCopilotType .bestAnswerCopilotResourceContent .scoresSection
{
  width: 50%;
}
.bestAnswerCopilotResourceContent .scoresLabelAndValueContainer {
  height: 65%;
  overflow: auto;
  padding: 10px;
}
.focusMode .bestAnswerCopilotResourceContent .scoresLabelAndValueContainer {
  height: 75%;
  overflow: auto;
}
.evaluateAnsCopilotType .bestAnswerCopilotResourceContent .scoresLabelAndValueContainer,
.evaluateAnsScoreCopilotType .bestAnswerCopilotResourceContent .scoresLabelAndValueContainer
 {
  /* height: 100%; */
  height: calc(100% - 45px)
}
.scoreInputField {
  width: 30%;
}
.bestAnswerCopilotResourceContent .scoreLabelAndValue {
  display: flex;
  column-gap: 10px;
}
.scoreLabel {
  width: 80%;
  text-align: left;
}
.itemCardPaperWhenCopilotTypeEvaluateAnswer,
.itemCardPaperWhenCopilotTypeEvaluateAnswerScore
{
  width: 58vw;
}
/* 
.updatedItemCard {
  width: 40%;
} */



.cardActions {}

.evaluateAnsScoreCopilotType .cardActions {
  margin-top: 43px;
}

.audioCopilotType .itemCardGrid {
  flex-grow: 1;
}
.audioCopilotType .contentBetweenArrows {
  width: 100%;
}

.audioCopilotType .beneathAssetSection {
  flex-grow: 1;
}

.audioTranscriptionContainer {
  flex-grow: 1;
}
.audioTranscriptionSectionBeneathButtons {
  border: 0.5px solid black;
  border-radius: 5px;
}