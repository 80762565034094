.tooltip {
    background-color: white;
    color: rgb(27, 27, 27);
    font-size: 0.9rem;
    padding: 12px;
    border-radius: 8px;
    box-shadow: 0 0 18px rgba(0, 0, 0, 0.226);
    max-width: 600px;
}

.tooltipArrow {
    color: white;
}