
/* shimmer effect css */
.shimmerWrapper {
    display: flex;
    flex-direction: column;
    gap: 16px;
    /* background-color: #f6f7f8b9; */
  }
  
  .shimmerCard {
    background-color: #f6f7f8;
    border-radius: 8px;
    padding: 16px;
    display: flex;
    flex-direction: column;
    gap: 12px;
    animation: shimmer 1.6s infinite linear;
    width: 100%;
}

.shimmerHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.shimmerLine {
    height: 16px;
    background-color: #e0e0e0;
    border-radius: 4px;
    margin-bottom: 8px;
}

.shimmerTag {
    width: 70px;
    height: 20px;
    background-color: #e0e0e0;
    border-radius: 16px;
}

.shimmerStatsContainer {
    display: flex;
    justify-content: space-between;
    gap: 10px;
}

.shimmerStatBox {
    width: 30%;
    height: 40px;
    background-color: #e0e0e0;
    border-radius: 8px;
}

.shimmerImagesContainer {
    display: flex;
    gap: 10px;
}

.shimmerImage {
    width: 70px;
    height: 50px;
    background-color: #e0e0e0;
    border-radius: 8px;
}

@keyframes shimmer {
    0% {
        background-position: -200px 0;
    }
    100% {
        background-position: calc(200px + 100%) 0;
    }
}