.audioTranscriptionSectionRoot {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.headerRow {
  display: flex;
}
.sectionHeading {
  display: flex;
  flex-grow: 1;
  font-size: 14px;
  font-weight: bold;
}
.buttonsContainer {
  display: flex;
  gap: 10px;
}
.transcriptionSection {
}
